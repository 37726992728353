import { useState } from 'react';
import { useJsApiLoader, GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { MarkerPoint } from '~/types';

interface Props {
    googleApiKey: any;
    markerPoints: MarkerPoint[];
}

export const FooterMap = ({ googleApiKey, markerPoints }: Props) => {
    const [activeMarker, setActiveMarker] = useState<number | null>(null);

    const center =
        markerPoints.length == 0
            ? { lat: 51.507181, lng: -0.127545 }
            : {
                  lat: markerPoints[0].attributes.Latitude,
                  lng: markerPoints[0].attributes.Longitude,
              };
    const handleActiveMarker = (marker: number | null) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const options = {
        disableDefaultUI: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleApiKey,
        libraries: ['places'],
        nonce: 'SAUK',
    });

    const onLoad = (map: google.maps.Map) => {
        if (markerPoints.length != 1) {
            const bounds = new google.maps.LatLngBounds();
            markerPoints?.forEach((markerPoint: MarkerPoint) =>
                bounds.extend({
                    lat: markerPoint.attributes.Latitude,
                    lng: markerPoint.attributes.Longitude,
                })
            );
            map.fitBounds(bounds);
        }
    };

    return (
        <>
            <div className="w-full border-white h-72 border-10">
                {!isLoaded ? (
                    <h1>Loading...</h1>
                ) : (
                    <GoogleMap
                        mapContainerClassName="map-container"
                        options={options}
                        onLoad={onLoad}
                        onClick={() => setActiveMarker(null)}
                        zoom={10}
                        center={markerPoints.length == 1 ? center : undefined}>
                        {markerPoints.map((markerPoint: MarkerPoint, index) => (
                            <MarkerF
                                key={index}
                                position={{
                                    lat: markerPoint.attributes.Latitude,
                                    lng: markerPoint.attributes.Longitude,
                                }}
                                onClick={() => handleActiveMarker(index)}>
                                {activeMarker === index && (
                                    <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                                        <div className="z-50">
                                            <div className="inset-0 flex items-center justify-center">
                                                <div className="relative w-full mx-auto ml-2 mr-2 bg-white rounded ">
                                                    <div className="">
                                                        <div>
                                                            <div className="flex items-start justify-between">
                                                                <h2 className="pt-5 mb-5 text-2xl font-extrabold font-SourceSans text-staff-300">
                                                                    {markerPoint.attributes.Name}
                                                                </h2>
                                                            </div>
                                                            <div className="flex items-center justify-start pr-5 space-x-3">
                                                                <svg
                                                                    width={13}
                                                                    height={15}
                                                                    viewBox="0 0 13 15"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M6.17399 14.832C6.19538 14.8444 6.2122 14.854 6.22411 14.8607L6.24389 14.8717C6.40175 14.9584 6.59758 14.9577 6.7556 14.872L6.77589 14.8607C6.7878 14.854 6.80462 14.8444 6.82601 14.832C6.8688 14.8072 6.92994 14.771 7.00681 14.7237C7.1605 14.6291 7.37745 14.4895 7.6366 14.3065C8.15402 13.9409 8.84441 13.3986 9.5363 12.6902C10.9134 11.2803 12.3438 9.16186 12.3438 6.4375C12.3438 3.21009 9.72741 0.59375 6.5 0.59375C3.27259 0.59375 0.65625 3.21009 0.65625 6.4375C0.65625 9.16186 2.0866 11.2803 3.4637 12.6902C4.1556 13.3986 4.84598 13.9409 5.3634 14.3065C5.62255 14.4895 5.8395 14.6291 5.99319 14.7237C6.07006 14.771 6.1312 14.8072 6.17399 14.832ZM6.5 8.5625C7.6736 8.5625 8.625 7.6111 8.625 6.4375C8.625 5.2639 7.6736 4.3125 6.5 4.3125C5.3264 4.3125 4.375 5.2639 4.375 6.4375C4.375 7.6111 5.3264 8.5625 6.5 8.5625Z"
                                                                        fill="#031A38"
                                                                    />
                                                                </svg>

                                                                <span className="text-lg font-normal text-left text-staff-100">
                                                                    {markerPoint.attributes.Location}
                                                                </span>
                                                            </div>
                                                            {/* <div className="px-3 pr-5 mt-1 text-left ">
                                          <span className="font-normal text-left text-md font-SourceSans text-staff-300">
                                            {markerPoint.attributes.Location}
                                          </span>
                                        </div> */}
                                                            <div className="pr-5 text-left ">
                                                                <div className="my-3 ">
                                                                    <div className="flex items-center justify-start space-x-2">
                                                                        <svg
                                                                            width={17}
                                                                            height={17}
                                                                            viewBox="0 0 17 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M1.0625 3.1875C1.0625 2.01389 2.01389 1.0625 3.1875 1.0625H4.15907C4.7685 1.0625 5.29973 1.47727 5.44754 2.06851L6.23081 5.2016C6.36041 5.72 6.16671 6.2656 5.73922 6.58621L4.8229 7.27345C4.72775 7.34481 4.70706 7.44931 4.73379 7.52228C5.53811 9.71797 7.28203 11.4619 9.47772 12.2662C9.55069 12.2929 9.65519 12.2723 9.72655 12.1771L10.4138 11.2608C10.7344 10.8333 11.28 10.6396 11.7984 10.7692L14.9315 11.5525C15.5227 11.7003 15.9375 12.2315 15.9375 12.8409V13.8125C15.9375 14.9861 14.9861 15.9375 13.8125 15.9375H12.2188C6.05732 15.9375 1.0625 10.9427 1.0625 4.78125V3.1875Z"
                                                                                fill="#031A38"
                                                                            />
                                                                        </svg>

                                                                        <span className="font-semibold text-staff-420 text-md">
                                                                            {markerPoint.attributes.Phone}
                                                                        </span>
                                                                    </div>
                                                                    <div className="my-3 ">
                                                                        <div className="flex items-center justify-start space-x-2">
                                                                            <svg
                                                                                width={17}
                                                                                height={17}
                                                                                viewBox="0 0 17 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M1.0625 6.14061V12.2188C1.0625 13.3924 2.0139 14.3438 3.1875 14.3438H13.8125C14.9861 14.3438 15.9375 13.3924 15.9375 12.2188V6.14061L9.61371 10.0322C8.93072 10.4525 8.06928 10.4525 7.38629 10.0322L1.0625 6.14061Z"
                                                                                    fill="#031A38"
                                                                                />
                                                                                <path
                                                                                    d="M15.9375 4.89304V4.78125C15.9375 3.60764 14.9861 2.65625 13.8125 2.65625H3.1875C2.0139 2.65625 1.0625 3.60764 1.0625 4.78125V4.89304L7.94315 9.12729C8.28464 9.33744 8.71536 9.33744 9.05685 9.12729L15.9375 4.89304Z"
                                                                                    fill="#031A38"
                                                                                />
                                                                            </svg>

                                                                            <span className="font-semibold text-staff-420 text-md">
                                                                                {markerPoint.attributes.Email}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </InfoWindowF>
                                )}
                            </MarkerF>
                        ))}
                    </GoogleMap>
                )}
            </div>
        </>
    );
};
